import React from "react";
import "./Card.css";

// Importing Images
import builder from "../Images/Home/HomeCard/builder.svg";
import clock from "../Images/Home/HomeCard/clock.svg";
import design from "../Images/Home/HomeCard/design.svg";
import roller from "../Images/Home/HomeCard/roller.svg";

const Card = () => {
  return (
    <div className="home-whyChooseUsSection">
      <h2 className="home-section-title">Why Choose Us?</h2>
      <span className="home-section-description">
        Convenient professional painting services. Tailored services, Customer
        Satisfaction.
      </span>
      <div className="home-cards-container">
        <div className="home-card">
          <div className="home-card-icon">
            <img
              src={roller}
              alt="roller"
              className="home-card-img"
              loading="lazy"
            />
          </div>
          <strong>Professional Painting Services</strong>
          <p>
            High-quality painting services for residential and commercial
            properties
          </p>
        </div>
        <div className="home-card">
          <div className="home-card-icon">
            <img
              src={builder}
              alt="builder"
              className="home-card-img"
              loading="lazy"
            />
          </div>
          <strong>Experienced Painters</strong>
          <p>
            Skilled and trained painters with years of experience in the
            industry
          </p>
        </div>
        <div className="home-card">
          <div className="home-card-icon">
            <img
              src={design}
              alt="design"
              className="home-card-img"
              loading="lazy"
            />
          </div>
          <strong>Custom Design</strong>
          <p>
            Tailored painting and design solutions to meet your unique
            preferences
          </p>
        </div>
        <div className="home-card">
          <div className="home-card-icon">
            <img
              src={clock}
              alt="clock"
              className="home-card-img"
              loading="lazy"
            />
          </div>
          <strong>Prompt & Reliable</strong>
          <p>
            Timely completion of projects with a focus on customer satisfaction
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
