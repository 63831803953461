import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'; // Optional: for styling
import App from './App'; // Main App component
import reportWebVitals from './reportWebVitals'; // Optional: for measuring performance

import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap for CSS
import "bootstrap/dist/js/bootstrap.bundle.min"; // Bootstrap bundle for JS

// Get the root element from the DOM
const container = document.getElementById('root');

// Create a root and render the app
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: for measuring performance
reportWebVitals();
