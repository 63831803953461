import { useState } from "react";
import "./Help.css";
import { faq } from "./faq";

function FAQItem({ id, question, answer, isOpen, toggle }) {
  return (
    <div
      className={`faq-item ${isOpen ? "open" : ""}`}
      onClick={() => toggle(id)}
    >
      <h4>{question}</h4>
      <span className="toggle-icon">{isOpen ? "−" : "+"}</span>
      {isOpen && <p>{answer}</p>}
    </div>
  );
}

function Help() {
  const [openItemId, setOpenItemId] = useState(null); // Track the open item by id

  const toggle = (id) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  return (
    <div>
      <body>
        <header>
          <div className="helpHeader">
            <h3>Our Frequently Asked Questions</h3>
            <hr />
            {faq.map((item) => (
              <FAQItem
                key={item.id}
                question={item.Question}
                answer={item.Answer}
                isOpen={openItemId === item.id}
                toggle={() => toggle(item.id)}
              />
            ))}
          </div>
        </header>

       
      </body>
    </div>
  );
}

export default Help;
