import React from "react";
import { Link } from "react-router-dom";

import "./home.css";
import FrontImg from "../Images/Home/FrontImg.jpg";

//Importing Other Components
import Slider from "./Slider";
import Card from "./Card";
import CommonQs from "./CommonQs";

function Home() {
  return (
    <div>
      <body className="homeBody">
        <header>
          <div className="">
            <div className="homeSlider">
              {/* Slider Component For Home Page */}
              <Slider />
              <h1 className="home-hero-sub-heading bodyLarge homeHeading2 h3">
                Bringing Life and Color to Your Home / Office. Professional
                Painting Services in Dublin, Ireland.
              </h1>
            </div>
          </div>
          <hr />
          {/* Content Below Home Slider */}
          <div className="homeContentContainer">
            <img
              src={FrontImg}
              alt="FrontImg"
              className="FrontImg"
              loading="lazy"
            />
            <div className="homeContent">
              <h2>Manage your Home with a splash of paint.</h2>
              <p>
                Discover the benefits of hiring our professional team. Most
                convenient way of transforming your space.
              </p>
              <button className="buttonOrangeFilled">
                <Link to="/Quote">Get a Free Quote</Link>
              </button>
              <button className="LearnMoreBtn">
                <Link to="/Services">Learn More →</Link>
              </button>
            </div>
          </div>
        </header>
        <hr />

        {/* Content Below Home Slider */}

        <div>
          <Card />
        </div>
        <hr />
        <div className="intro-section">
          <h1 className="intro-title">
            Transform Your Space with BeePainting Fast and Reliable.
          </h1>
          <span className="intro-description">
            We are a professional painting company dedicated to providing
            top-quality painting services for residential and commercial spaces.
            With our team of skilled painters and designers, we can transform
            any space into a work of art.
          </span>
          <button className="learn-more-btn">
            <Link to="/Services">Learn More →</Link>
          </button>
        </div>
        <CommonQs />
      </body>
    </div>
  );
}

export default Home;
