import React, { useState, useEffect, useCallback } from "react";
import "./Slider.css";
import slider1 from "../Images/Home/imgSlider1.jpg";
import slider2 from "../Images/Home/imgSlider2.jpg";
import slider3 from "../Images/Home/imgSlider3.jpg";
import slider4 from "../Images/Home/imgSlider4.jpg";

const Slider = () => {
  const images = [slider3, slider2, slider1, slider4];
  const captions = [
    {
      title: "Painting Services Dublin",
      text: "We provide painting services!",
    },
    { title: "Huge number of services", text: "We provide painting services." },
    { title: "Huge number of services", text: "We provide painting services." },
    { title: "Huge number of services", text: "We provide painting services." },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = useCallback(() => {
    setCurrentIndex((currentIndex + 1) % images.length);
  }, [currentIndex, images.length]);

  const prevSlide = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const autoSlide = setInterval(nextSlide, 4000);
    return () => clearInterval(autoSlide);
  }, [nextSlide]);

  return (
    <div className="slider">
      <div
        className="sliderWrapper"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentIndex ? "active" : ""}`}
            style={{ backgroundImage: `url(${image})` }}
            loading="lazy"
          >
            <div className="caption">
              <h1>{captions[index].title}</h1>
              <h5>{captions[index].text}</h5>
            </div>
          </div>
        ))}
      </div>
      <button className="prev" onClick={prevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

export default Slider;
