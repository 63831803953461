import "./styles.css";
import "./navigationBar.css";
import "./footer.css";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
} from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

// All Pages being imported
import AboutUs from "./client/AboutUs/AboutUs";
import Help from "./client/Help/Help";
import Home from "./client/Home/Home";
import Quote from "./client/Quote/Quote";
import Services from "./client/Services/Services";

//Images imported for the Navigation Bar
import mail from "./client/Images/NavBar/mail.svg";
import menuClose from "./client/Images/NavBar/menuClose.svg";
import menuOpen from "./client/Images/NavBar/menuOpen.svg";
import phone from "./client/Images/NavBar/phone.svg";
import navHeaderImg from "./client/Images/NavBar/navHeaderImg.jpg";
import whatsApp from "./client/Images/NavBar/WhatsAppButtonGreenSmall.svg";


//Images Imported for Footer
import facebookIcon from "./client/Images/Footer/iconFacebook.svg";
import instaIcon from "./client/Images/Footer/iconInsta.svg";
import tiktokIcon from "./client/Images/Footer/iconTiktok.svg";

export default function App() {
  // Functions for Navigation Bar on Mobile
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  function toggleSideBar() {
    setSidebarVisible(!isSidebarVisible);
  }

  function hideSidebar() {
    setSidebarVisible(false);
  }
  return (
    <div className={`frontInfo ${isSidebarVisible ? "sidebar-visible" : ""}`}>
      {/* Front Page Name & Logo, Phone Number & Email */}
      <header className="header" style={{ backgroundColor: `#252525` }}>
        <h4 className="company-name">BeePainting Dublin</h4>
        <div className="contactInfo">
          <a className="phone" href="tel:+353894024355">
            <img alt="phone" src={phone} />
            089 402 4355
          </a>
          <a
            aria-label="Chat on WhatsApp"
            href="https://wa.me/353894024355?text=I%27m%20inquiring%20about%20your%20services"
          >
            <img alt="Chat on WhatsApp" src={whatsApp} />
          </a>
          <a className="email" href="mailto:beepaintingdublin@gmail.com">
            <img alt="email" src={mail} />
            beepaintingdublin@gmail.com
          </a>
        </div>
      </header>

      {/* Navigation Bar */}
      <Router>
      
        <nav className={`sidebar ${isSidebarVisible ? "visible" : ""}`}>
          <ul>
            <li onClick={hideSidebar}>
                <img alt="menuClose" src={menuClose} />
            </li>
            <li className="top-nav" onClick={hideSidebar}>
              <Link to="/Home" className="top-nav-link">
                Home
              </Link>
            </li>
            <li className="top-nav" onClick={hideSidebar}>
              <Link to="/Services" className="top-nav-link">
                Services
              </Link>
            </li>
            <li className="top-nav" onClick={hideSidebar}>
              <Link to="/Quote" className="top-nav-link">
                Request a Quote
              </Link>
            </li>
            <li className="top-nav" onClick={hideSidebar}>
              <Link to="/Help" className="top-nav-link">
                Help
              </Link>
            </li>
            <li className="top-nav" onClick={hideSidebar}>
              <Link to="/AboutUs" className="top-nav-link">
                About Us
              </Link>
            </li>
          </ul>
        </nav>

        {/* Navigation Bar */}
        <nav className="top-navigation">
          <ul>
            <li className="hideOnMobile">
              <Link to="/Home" className="top-nav-link">
                Home
              </Link>
            </li>
            <li className="hideOnMobile">
              <Link to="/Services" className="top-nav-link">
                Services
              </Link>
            </li>
            <li className="hideOnMobile">
              <Link to="/Quote" className="top-nav-link">
                Request a Quote
              </Link>
            </li>
            <li className="hideOnMobile">
              <Link to="/Help" className="top-nav-link">
                Help
              </Link>
            </li>
            <li className="hideOnMobile">
              <Link to="/AboutUs" className="top-nav-link">
                About Us
              </Link>
            </li>
            <li className="menuBtn" onClick={toggleSideBar}>
                <img alt="menuOpen" src={menuOpen} />
            </li>
          </ul>
        </nav>

        <div
          className={`main-content ${
            isSidebarVisible ? "sidebar-visible" : ""
          }`}
        >
          {/* Routes For My Page */}
          <Routes>
            
            {/* Default Page is always Home */}
            <Route path="/" element={<Navigate to="/Home" />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Quote" element={<Quote />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Help" element={<Help />} />
          </Routes>

          {/* Footer For Main Page */}
        </div>
        <hr />
        <footer
          className="footer py-5 silver-text"
          style={{
            backgroundImage: `url(${navHeaderImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Container>
            <Row>
              <Col md={3}>
                <h5 className="footerHeading">About Us</h5>
                <p style={{ color: "white" }}>
                  "We're committed to excellence, serving you with passion and
                  care, we strive to exceed expectations in everything we do.
                  Thank you for choosing us."
                </p>
              </Col>
              <Col md={3}>
                <h5 className="footerHeading">Quick Links</h5>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <Link to="/Home" className="nav-link p-0 custom-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/Services" className="nav-link p-0 custom-link">
                      Services
                    </Link>
                  </li>
                  
                  <li className="nav-item mb-2">
                    <Link to="/Quote" className="nav-link p-0 custom-link">
                      Request a Quote
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/Help" className="nav-link p-0 custom-link">
                      Help
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link to="/AboutUs" className="nav-link p-0 custom-link">
                      About Us
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={3} className="footerContactSection">
                <h5 className="footerHeading">Contact Us</h5>
                <br />
                <div className="footerContactInfo">
                  <a className="phone" href="tel:+353894024355">
                    <img alt="phone" src={phone} loading="lazy" />
                    089 402 4355
                  </a>
                  <br />
                  <a
                    aria-label="Chat on WhatsApp"
                    href="https://wa.me/353894024355?text=I%27m%20inquiring%20about%20your%20services"
                    className="whatsapp-link"
                  >
                    <img alt="Chat on WhatsApp" src={whatsApp} loading="lazy" />
                  </a>
                  <br />
                  <a
                    className="email"
                    href="mailto:beepaintingdublin@gmail.com"
                  >
                    <img alt="email" src={mail} loading="lazy" />
                    beepaintingdublin@gmail.com
                  </a>
                </div>
                <address>Carpenterstown, Co. Dublin, Ireland</address>
              </Col>
              <Col md={3} className="follow-us-section">
                <h5 className="footerHeading">Follow Us</h5>
                <a className="facebook-icon" href="https://www.facebook.com">
                  <img
                    alt="facebook"
                    src={facebookIcon}
                    style={{ backgroundColor: "white" }}
                    loading="lazy"
                  />
                </a>
                <a className="instagram-icon" href="https://www.instagram.com">
                  <img
                    alt="instagram"
                    src={instaIcon}
                    style={{ backgroundColor: "white" }}
                    loading="lazy"
                  />
                </a>
                <a className="tiktok-icon" href="https://www.tiktok.com">
                  <img
                    alt="tiktok"
                    src={tiktokIcon}
                    style={{ backgroundColor: "white" }}
                    loading="lazy"
                  />
                </a>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p className="companyRights">
                  © {new Date().getFullYear()} BeePainting Dublin Inc. All rights
                  reserved.
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
        
      </Router>
    </div>
  );
}
