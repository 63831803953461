import React from "react";
import "./AboutUs.css";
import { Link } from "react-router-dom";
import toolsHouse from "../Images/AboutUs/toolsHouse.jpg";

import mail from "../Images/NavBar/mail.svg";
import phone from "../Images/NavBar/phone.svg";
import whatsApp from "../Images/NavBar/WhatsAppButtonGreenSmall.svg";

function AboutUs() {
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <img src={toolsHouse} alt="toolsHouse" className="about-us-img" />
        <div className="about-us-text">
          <h2 className="about-us-title">About Us</h2>
          <p className="about-us-paragraph">
            Locally owned and operated business, Based in Dublin, Ireland, we
            offer our reliable services in Dublin and any other surrounding
            counties.
          </p>
          <p className="about-us-paragraph">
            Experienced painters providing reliable and cost-effective painting
            at a high professional standard, with the intention of providing a
            tidy and harmless setting for your family and your home, as well as
            your office.
          </p>
          <p className="about-us-paragraph">
            Our working teams are well trained and experienced, trustworthy and
            take great care of their clients. Always looking forward to assuring
            the best service and our assistance at all times.
          </p>
        </div>
      </div>

      <div className="opening-hours">
        <h3 className="opening-hours-title">Opening Hours</h3>
        <table className="table table-striped table-bordered">
          <thead className="thead">
            <tr>
              <th>Day</th>
              <th>Hours</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Monday</td>
              <td>08:00 - 18:00</td>
            </tr>
            <tr>
              <td>Tuesday</td>
              <td>08:00 - 18:00</td>
            </tr>
            <tr>
              <td>Wednesday</td>
              <td>08:00 - 18:00</td>
            </tr>
            <tr>
              <td>Thursday</td>
              <td>08:00 - 18:00</td>
            </tr>
            <tr>
              <td>Friday</td>
              <td>08:00 - 16:00</td>
            </tr>
            <tr>
              <td>Saturday</td>
              <td>08:00 - 16:00</td>
            </tr>
            <tr>
              <td>Sunday</td>
              <td>Closed</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="contact-container-custom">
        <div className="contact-info-custom">
          <button className="buttonOrangeFilled-custom">
            <Link to="/Quote" className="link-custom">
              Get a Free Quote
            </Link>
          </button>
          <a className="phone-custom" href="tel:+353894024355">
            <img
              alt="phone"
              src={phone}
              loading="lazy"
              className="icon-custom"
            />
            Call Us: 089 402 4355
          </a>
          <a
            className="whatsapp-custom"
            aria-label="Chat on WhatsApp"
            href="https://wa.me/353894024355?text=I%27m%20inquiring%20about%20your%20services"
          >
            <img
              alt="Chat on WhatsApp"
              src={whatsApp}
              loading="lazy"
              className="icon-custom"
            />
          </a>
          <a className="email-custom" href="mailto:beepaintingdublin@gmail.com">
            <img
              alt="email"
              src={mail}
              loading="lazy"
              className="icon-custom"
            />
            beepaintingdublin@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
