export const faq = [
  {
    id: 1,
    Question: "How Do I Get A Quote?",
    Answer:
      "You can fill in our form online, or Contact Us Below by phone or We will get back to you asap with a personalized quote typically 24-48 hours.",
  },
  {
    id: 2,
    Question: "How fast can you do the job ?",
    Answer:
      "It depends on the job, we have a reliable team, typically we assign 2-3 painters per site.",
  },
  {
    id: 3,
    Question: "What types of services do you offer?",
    Answer:
      "We take care of everything moving furniture, taking downcurtains, portraits. We use dust sheets, and plastic sheets to protect your floors, furniture. We maintain quality and safety standards and work in a careful manner. Our team of experienced painters can handle any project big or small.",
  },
  {
    id: 4,
    Question: "How reliable is your service?",
    Answer:
      "We prepare our work before we start painting so our work lasts longer. We treat mold with mold killer, fill cracks and holes with filler before we start painting. Any woodwork, furniture, or doors will be sanded down before painting.",
  },
  {
    id: 5,
    Question: "What kind of paint do you use?",
    Answer:
      "We discuss this with you, the main brands we use are Dulux, Farrow & Ball, ColourTrend, Fleetwood.",
  },
  {
    id: 6,
    Question:
      "Old house, and paint is peeling away looking bubbly. How do you fix this?",
    Answer:
      "The walls need to be stabilisized, we would plaster the wall, remove any mold, fill cracks, we have to see the building in order to be fully sure.",
  },
  {
    id: 7,
    Question: "Can I provide additional instructions for my project?",
    Answer:
      "Absolutely! Feel free to provide any additional instructions or specifications for your project. This will help us understand your requirements better.",
  },
  {
    id: 8,
    Question: "What if I have more questions or need further assistance ?",
    Answer:
      "If you have any more questions or need further assistance, please don't hesistate to contact us. You can reach out to us via phone or email. Our friendly team will be happy to help you.",
  },
  {
    id: 9,
    Question: "Do you offer color consultation services?",
    Answer:
      "Yes, we offer professional color consultation services to help you choose the perfect color scheme for your space. Our experts consider lighting, space size, and your personal preferences to provide recommendations.",
  },
  {
    id: 10,
    Question: "Are your painting materials eco-friendly?",
    Answer:
      "We are committed to environmental responsibility and use eco-friendly paints and materials whenever possible. These products provide excellent coverage and durability while being safer for the environment and your home.",
  },
  {
    id: 11,
    Question: "How do you ensure the quality of your work?",
    Answer:
      "Our team follows a rigorous quality assurance process that includes thorough preparation, precise painting techniques, and a final walkthrough with the client to ensure satisfaction. We use high-quality materials and adhere to industry best practices.",
  },
  {
    id: 12,
    Question: "What measures do you take to protect my property?",
    Answer:
      "We take great care to protect your property during the painting process. This includes using drop cloths, plastic sheeting, and masking tape to cover floors and furniture, and removing or protecting light fixtures and other delicate items.",
  },
  {
    id: 13,
    Question: "How long will the painting project take?",
    Answer:
      "The duration of the project depends on its scope, the condition of the surfaces, and weather conditions for exterior projects. We provide a project timeline with our quote, and we strive to minimize any inconvenience.",
  },
];
