import React from "react";
import "./Services.css";
import { Link } from "react-router-dom";
import ServicesCard from "./ServicesCard";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";

import mail from "../Images/NavBar/mail.svg";
import phone from "../Images/NavBar/phone.svg";
import whatsApp from "../Images/NavBar/WhatsAppButtonGreenSmall.svg";

function Services() {
  return (
    <div>
      <body className="servicesBody">
        <div>
          <h2 className="h1">
            <b>Professional House Painting in Dublin Ireland</b>
          </h2>
          <p>
            <b>Part of our services include:</b>
          </p>
          <ServicesCard />
          <hr />
          <Feature1 />
          <hr />
          <Feature2 />
          <hr />
        </div>
        <div className="contact-container-custom">
          <div className="contact-info-custom">
            <button className="buttonOrangeFilled-custom">
              <Link to="/Quote" className="link-custom">
                Get a Free Quote
              </Link>
            </button>
            <a className="phone-custom" href="tel:+353894024355">
              <img
                alt="phone"
                src={phone}
                loading="lazy"
                className="icon-custom"
              />
              Call Us: 089 402 4355
            </a>
            <a
              className="whatsapp-custom"
              aria-label="Chat on WhatsApp"
              href="https://wa.me/353894024355?text=I%27m%20inquiring%20about%20your%20services"
            >
              <img
                alt="Chat on WhatsApp"
                src={whatsApp}
                loading="lazy"
                className="icon-custom"
              />
            </a>
            <a
              className="email-custom"
              href="mailto:beepaintingdublin@gmail.com"
            >
              <img
                alt="email"
                src={mail}
                loading="lazy"
                className="icon-custom"
              />
              beepaintingdublin@gmail.com
            </a>
          </div>
        </div>
      </body>
    </div>
  );
}
export default Services;
