import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./EmailSend.css"; // Make sure to create this CSS file

function EmailSend() {
  const [message, setMessage] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const maxChars = 600;

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
    setCharCount(value.length);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_3z0wsye", "template_145ayta", form.current, {
        publicKey: "ZAEEhKEkqMRJUQA7q",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsSubmitted(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="quoteFormContainer">
      {isSubmitted ? (
        <div className="formSubmissionThanks">
          <h3>Thank you for your submission!</h3>
          <p>We will get back to you shortly.</p>
        </div>
      ) : (
        <>
          <h1 className="quoteH1">
            <b>Request a Quote</b>
          </h1>
          <form ref={form} onSubmit={sendEmail} className="quoteForm">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="user_name"
                  id="name"
                  required
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  className="form-control"
                  type="phone"
                  name="user_phone"
                  id="phone"
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <label htmlFor="email" className="col-sm-2 col-form-label">
                Email
              </label>
              <input
                type="email"
                name="user_email"
                id="email"
                required
                className="form-control"
              />
            </div>

            <div className="form-row">
              <label htmlFor="address" className="col-sm-2 col-form-label">
                Address
              </label>
              <input
                type="text"
                name="user_address"
                id="address"
                required
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Briefly describe your request:
              </label>
              <textarea
                name="message"
                id="description"
                value={message}
                onChange={handleChange}
                maxLength={maxChars}
                required
                className="form-control"
                rows="6"
              ></textarea>
              <label>
                {charCount} out of {maxChars} characters
              </label>
            </div>
            <div>
              <button type="submit" value="Send" className="submitQuoteBtn">
                Submit
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default EmailSend;
