import React from "react";
import "./Feature1.css";

function Feature1() {
  return (
    <div className="features-container">
      <div className="feature">
        <strong>Interior & Exterior Painting</strong>
        <p>
          High-quality painting services for residential and commercial
          properties, transformative touch of our high-quality painting
          services, enduring beauty and meticulous craftsmanship.
        </p>
      </div>
      <div className="feature">
        <strong>Mold Remover & Wall Repairs</strong>
        <p>
          Expert repair services for damaged walls and drywalls. Specialized
          treatment to remove mold from walls. Delivering superior results and
          ensuring lasting quality for your home or office. Say goodbye to
          imperfections and mold issues, as we restore and refresh your living
          spaces.
        </p>
      </div>
      <div className="feature">
        <strong>Prompt and Reliable Service</strong>
        <p>
          Timely completion of projects with a focus on customer satisfaction.
          Our commitment to prompt and reliable service sets us apart, ensuring
          your peace of mind throughout the process.
        </p>
      </div>
      <div className="feature">
        <strong>Hand Painting Any Woodwork, Metal, or Furniture</strong>
        <p>
          Transform your living spaces with our meticulous hand-painting
          services. Elevate the beauty of your woodwork, metal fixtures, or
          furniture with our expert touch, ensuring lasting quality and timeless
          elegance.
        </p>
      </div>
      <div className="feature">
        <strong>Comprehensive Paint Supply</strong>
        <p>
          Enjoy a hassle-free experience with our full paint supply service. We
          provide top-notch paint brands, ensuring quality and longevity for
          every stroke.
        </p>
      </div>
      <div className="feature">
        <strong>Expert Plastering and Drywall Services</strong>
        <p>
          Achieve a flawless finish with our professional plastering and drywall
          services. We create a smooth canvas for your paint, ensuring a
          stunning and even application.
        </p>
      </div>
    </div>
  );
}

export default Feature1;
