import React from "react";
import "./servicesCard.css";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

//importing Images
import BlueBedroom from "../Images/Services/BlueBedroom.jpg";
import WhiteBedroom from "../Images/Services/WhiteBedroom.jpg";
import WhiteKitchen from "../Images/Services/WhiteKitchen.jpg";
import BlueKitchen from "../Images/Services/BlueKitchen.jpg";
import WoodPaint from "../Images/Services/WoodPaint.jpg";
import HandPaint from "../Images/Services/HandPaint.jpg";
import BlueDesign from "../Images/Services/BlueDesign.jpg";
import ColourChoice from "../Images/Services/ColourChoice.jpg";

const ServicesCard = () => {
  return (
    <div>
      <ul>
        <CardGroup>
          <Card>
            <Card.Img
              variant="top"
              src={BlueBedroom}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>Interior Colours</Card.Title>
              <Card.Text>
                Add Energy and Vibrant Colours to your Space.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src={WhiteBedroom}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>Interior White</Card.Title>
              <Card.Text>
                Brighten with White to elevate the Brightness.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src={WhiteKitchen}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>Gloss & Undercoat Whites</Card.Title>
              <Card.Text>
                For a Lighter, Airier Feel, Brightening Up the Space
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src={BlueKitchen}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>SatinWood Colours</Card.Title>
              <Card.Text>Vibrant Colors for a Dynamic Interior</Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <CardGroup>
          <Card>
            <Card.Img
              variant="top"
              src={ColourChoice}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>Amazing Colours To Choose From</Card.Title>
              <Card.Text>
                Interior Testers, Transform with Colorful Accents
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src={WoodPaint}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>Metal & Wood Painting</Card.Title>
              <Card.Text>
                Protective Coating for Longevity, a Restoration and Revival.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src={HandPaint}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>Hand Painted</Card.Title>
              <Card.Text>
                Beauty and Precision, Detail-Oriented Finishes, with a Tailored
                Aesthetic Appeal
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img
              variant="top"
              src={BlueDesign}
              loading="lazy"
              className="serviceCardImg"
            />
            <Card.Body>
              <Card.Title>Home Decor & Interior Design</Card.Title>
              <Card.Text>
                Artistic Flair in Everyday Spaces, Sustainable and Unique Decor
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </ul>
    </div>
  );
};
export default ServicesCard;
